import './index.css';
import iphone from '../../assets/images/iPhone12ProMax.png';
import samsung from '../../assets/images/samsungGalaxyS20Ultra.png';
import appStoreBadge from '../../assets/images/black.svg';
import googlePlayBadge from '../../assets/images/google-play-badge.png';

const Index = () => {
  return (
    <div className='customer'>
      <div className='customer_phones'>
        <img src={iphone} className='customer__iphone' alt='iPhone' />
        <img src={samsung} className='customer__samsung' alt='Samsung' />
      </div>
      <div className='customer__links'>
        <p className='customer__subTitle'>
          Our Customer App <div className='customer__line-through' />
        </p>
        <h1 className='customer__title'>
          Let's explore the <br /> DrinKing customer app
        </h1>
        <p className='customer__desc'>
          Your customers will be able to download the app from both the Apple
          App Store and the Google Play Store, if they haven't already, and
          select what they would like from your menu and purchase through the
          app, paying with either apple/google pay or from their debit/ credit
          card.
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='customer__links-images'>
            <a
              href='https://apps.apple.com/gb/app/drinking-app/id1562580887'
              target='_blank'
              rel='noreferrer'
            >
              <img src={appStoreBadge} style={{ height: 55 }} alt='App Store' />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.drinking.app'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={googlePlayBadge}
                style={{ height: 80, width: 205 }}
                alt='Google Play Store'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
