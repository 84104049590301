import "./index.css";
import { useContext, useState } from "react";
import Accordion from "../../components/Accordion";
import { PostContext } from "../../contexts/PostContext";

const Index = () => {
  const { faqs } = useContext(PostContext);
  const [clicked, setClicked]: any = useState(false);

  const toggle = (index: any) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };
  return (
    <div className="faq">
      <div className="faq__container">
        <h1>Frequently asked questions</h1>
        {faqs?.map((faq: any) => (
          <Accordion
            key={faq.id}
            toggle={toggle}
            clicked={clicked}
            index={faq.id}
            label={faq.question}
            content={faq.answer}
          />
        ))}
      </div>
    </div>
  );
};
export default Index;
