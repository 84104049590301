import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import './index.css';

const Index = () => {
  const { register, handleSubmit, reset, errors } = useForm();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    setSent(false);

    const config: any = {
      method: 'post',
      url: 'https://drinking.uk/v1/api/contact/venue',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        company_name: `Account Deletion - Phone: ${data.phone || 'Unknown'}`,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        message: `Account Deletion - Reason: ${data.reason}`,
      }),
    };
    axios(config)
      .then(function (response) {
        setSent(true);
        reset();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div id='ac_form'>
      <div className='ac_form__desc'>
        <h2>Permanently delete your Drinking account</h2>
        <p>Deleting a Drinking profile will permanently delete all data for that profile within 30 business days after a request.</p>
        <p>--- OR ---</p>
        <p>You could delete your Drinking profile in app, instantly! Go to settings and then click "Delete Account" under Legal section.</p>
        <ul>
          <li>You won't be able to reactivate your account.</li>
          <li>You'll no longer be able to use Drinking App.</li>
          <li>Other users cannot see your profile info.</li>
          <li>
            <b>DrinKing will not be able to process any refunds on any of your orders.</b>
          </li>
        </ul>
        <p>
          Learn more about our{' '}
          <a href='/privacy' target='_blank'>
            Privacy Policy.
          </a>
        </p>
      </div>
      <form className='ac_form__submit' onSubmit={handleSubmit(onSubmit)}>
        <h3>Please complete your form to delete your Drinking profile.</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
            <input name='firstName' type='text' ref={register({ required: true, minLength: 2, maxLength: 64 })} placeholder='First Name *' />
            {errors && errors?.firstName && <span>First Name field is required</span>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
            <input name='lastName' type='text' ref={register({ required: true, minLength: 2, maxLength: 64 })} placeholder='Last Name *' />
            {errors && errors?.lastName && <span>Last Name field is required</span>}
          </div>
        </div>
        <input name='email' type='email' ref={register({ required: true, maxLength: 128 })} placeholder='Email *' />
        <input name='phone' type='text' ref={register({ maxLength: 32 })} placeholder='Phone Number' />
        {errors && errors?.email && <span> Email field is required</span>}
        <p>Why are you leaving?</p>
        <label className='radio-group'>
          <input type='radio' name='reason' value='TROUBLE_GETTING_STARTED' ref={register({ required: true, minLength: 2, maxLength: 1024 })} />
          Trouble getting started
        </label>
        <label className='radio-group'>
          <input type='radio' name='reason' value='PRIVACY_CONCERN' ref={register({ required: true, minLength: 2, maxLength: 1024 })} />
          Privacy consern
        </label>
        <label className='radio-group'>
          <input type='radio' name='reason' value='DUPLICATE_ACCOUNT' ref={register({ required: true, minLength: 2, maxLength: 1024 })} />I have a duplicate account
        </label>
        <label className='radio-group'>
          <input type='radio' name='reason' value='NEED_A_BREAK' ref={register({ required: true, minLength: 2, maxLength: 1024 })} />
          Just needs a break
        </label>
        <label className='radio-group'>
          <input type='radio' name='reason' value='HACKED' ref={register({ required: true, minLength: 2, maxLength: 1024 })} />
          My account has been hacked
        </label>
        <label className='radio-group'>
          <input type='radio' name='reason' value='OTHER' ref={register({ required: true, minLength: 2, maxLength: 1024 })} />
          Other
        </label>
        {errors && errors?.reason && <span> Reason field is required</span>}
        <input type='submit' disabled={loading} value='DELETE' />
        {sent && <p style={{ textAlign: 'center', marginTop: 0 }}>Your request has been sent. Thank you!</p>}
      </form>
    </div>
  );
};

export default Index;
