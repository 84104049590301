import './index.css';
import BackOfficeItalian from '../../components/BackOfficeItalian';
import FormItalian from '../../components/FormItalian';
import bruschibanner from '../../assets/images/bruschibanner.png';
import samsungGalaxyS20Ultra from '../../assets/images/samsungGalaxyS20Ultra.png';
import ipadpro13 from '../../assets/images/ipadpro13.png';
import fiscal from '../../assets/images/fiscal.png';
import testimonial from '../../assets/images/testimonial.png';

const BruschiPage = () => {
  return (
    <div className="bruschi">
      <img src={bruschibanner} alt="Bruschi Banner" className="bruschi__banner" />
      <div className="bruschi__content">
        <div className="bruschi__testimonial-section">
          <h1>Ordina direttamente dalla tua spiaggia nel 2024!</h1>
          <p className="bruschi__testimonial">
            “Siamo entusiasti di offrire ai nostri clienti una tecnologia di ordinazione all'avanguardia.
            Abbiamo collaborato con DrinKing per aiutare i nostri clienti ad aumentare i ricavi, migliorare l'efficienza e ridurre le code.
            Registrati e inizia a prendere ordini - senza lasciare il tuo lettino!”
          </p>
          <p className="bruschi__author">Luigi Laura da Bruschi&amp;C.</p>
        </div>

        <h2 className="bruschi__products-title">Prodotti</h2>
        <div className="bruschi__products">
          <div className="bruschi__product">
            <img src={samsungGalaxyS20Ultra} alt="Customer App" className="product__image" />
            <p>La nostra app per i clienti</p>
            <ul>
              <li>Ordinazione con QR per ritiro al bar e servizio al tavolo</li>
              <li>Google Maps con posizione della sede</li>
            </ul>
          </div>
          <div className="bruschi__product">
            <img src={ipadpro13} alt="Venue App" className="product__image" />
            <p>La nostra app per le sedi</p>
            <ul>
              <li>Gestisci e processa gli ordini</li>
              <li>Ordina per tavolo o ritiro</li>
            </ul>
          </div>
        </div>
        <BackOfficeItalian />

        <h2 className="bruschi__products-title">Integrazione fiscale</h2>
        <div className="bruschi__fiscal">
          <p>
            DrinKing gestisce l'integrazione fiscale obbligatoria italiana per facilitare l'emissione dello scontrino.
            Come cliente esistente di Bruschi&amp;C., possiamo integrare nella tua attuale stampante fiscale.
            Una volta che il cliente ha effettuato l'ordine tramite l'app, verrà emesso uno scontrino.
          </p>
          <img src={fiscal} alt="Integrazione Fiscale" />
        </div>

        <div className="bruschi__client-testimonial">
          <h2 className="bruschi__products-title">Testimonianza del cliente: Bagno Aurora</h2>
          <img src={testimonial} alt="Testimonianza del Cliente" />
        </div>

        <div className="bruschi__signup-form">
          <FormItalian />
        </div>
      </div>
    </div>
  );
};

export default BruschiPage;
