import React from 'react';
import ReactPlayer from 'react-player';
import './index.css'

const index = () => {
  return (
    <div
      style={{
        height: '70vh',
        paddingBottom: '5%',
        paddingLeft: '1%',
        paddingRight: '1%',
        paddingTop: '5%',
        width:'98%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ReactPlayer className="react-player"
      playing
      width="100%"
      height="100%" url='https://vimeo.com/593190090' controls={true} />
    </div>
  );
};

export default index;
