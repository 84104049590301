import './index.css';
import appStoreBadge from '../../assets/images/black.svg';
import googlePlayBadge from '../../assets/images/google-play-badge.png';
import ipad from '../../assets/images/ipadpro13.png';
import iphone from '../../assets/images/iphone12black.png';

const Index = () => {
  return (
    <div className='bartender'>
      <div className='bartender__links'>
        <p className='bartender__subTitle'>
          Our bartender App <div className='bartender__line-through' />
        </p>
        <h1 className='bartender__title'>
        Let's explore the <br /> DrinKing bartender app
        </h1>
        <p className='bartender__desc'>
          Your bartender receives the order through our separate bar tender app
          and prepares it, whether you're operating table service, bar pickup or
          both. When the bartender is ready to complete the order they simply
          scan the customers QR code and takes them their drink. It's that
          simple!
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='bartender__links-images'>
            <a
              href='https://apps.apple.com/gb/app/drinking-venues/id1554283520'
              target='_blank'
              rel='noreferrer'
            >
              <img src={appStoreBadge} style={{ height: 55 }} alt='App Store' />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.drinking.venues'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={googlePlayBadge}
                style={{ height: 80, width: 205 }}
                alt='Google Play Store'
              />
            </a>
          </div>
        </div>
      </div>
      <div
      className="bartender__devices"
    
      >
        <img src={iphone} className='bartender__iphone' alt='iPhone' />
        <img src={ipad} className='bartender__samsung' alt='Samsung' />
      </div>
    </div>
  );
};

export default Index;
