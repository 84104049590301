import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import './index.css';

const Index = () => {
  const { register, handleSubmit, reset, errors } = useForm();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    setSent(false);

    const config: any = {
      method: 'post',
      url: 'https://drinking.uk/v1/api/contact/venue',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        company_name: data.companyName,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        message: data.message,
      }),
    };
    axios(config)
      .then(function (response) {
        setSent(true);
        reset();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div id='form'>
      <div className='form__desc'>
        <h2>Want to accept DrinKing at your Venue?</h2>
        <p>
          Due to our zero setup costs and minimal equipment required to run our
          system, you can enquire to arrange a day to setup a free trial and
          demo to see if it works for you before you make any financial
          commitments.
        </p>
        <p> Bring your venue into the 21st century by filling out the form!</p>
      </div>
      <form className='emailForm' onSubmit={handleSubmit(onSubmit)}>
        <h3>Please email us using the form below.</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '49%' }}
          >
            <input
              name='firstName'
              type='text'
              ref={register({ required: true, minLength: 2, maxLength: 64 })}
              placeholder='First Name'
            />
            {errors && errors?.firstName && (
              <span>First Name field is required</span>
            )}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '49%' }}
          >
            <input
              name='lastName'
              type='text'
              ref={register({ required: true, minLength: 2, maxLength: 64 })}
              placeholder='Last Name'
            />
            {errors && errors?.lastName && (
              <span>Last Name field is required</span>
            )}
          </div>
        </div>
        <input
          name='email'
          type='email'
          ref={register({ required: true, maxLength: 128 })}
          placeholder='Email'
        />
        {errors && errors?.email && <span> Email field is required</span>}
        <input
          name='companyName'
          type='text'
          ref={register({ required: true, minLength: 2, maxLength: 64 })}
          placeholder='Company Name'
        />
        {errors && errors?.companyName && (
          <span>Company Name field is required</span>
        )}
        <textarea
          name='message'
          ref={register({ required: true, minLength: 2, maxLength: 1024 })}
          placeholder='Message'
          rows={4}
          cols={50}
        />
        {errors && errors?.message && <span> Message field is required</span>}
        <input type='submit' disabled={loading} />
        {sent && (
          <p style={{ textAlign: 'center', marginTop: 0 }}>
            Your request has been sent. Thank you!
          </p>
        )}
      </form>
    </div>
  );
};

export default Index;
