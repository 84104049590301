import React from 'react';

function Slide(props: any) {
  return (
    <div
      onClick={props.onClick}
      style={{
        ...styles.card,
        opacity: `${props.opacity}`,
        left: `${props.x}px`,
        top: `${props.y}px`,
        zIndex: props.z_index,
        transform: `translate(-50%, -50%) scale(${props.scale})`,
      }}
    >
      <img
        style={styles.image}
        src={props.picsum_image.img}
        alt={props.picsum_image.img}
      />
      <h4 style={{ textAlign: 'center' }}>{props.picsum_image.title}</h4>
    </div>
  );
}

const styles: any = {
  card: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '300px',
    width: '570px',
    boxSizing: 'border-box',
    filter: 'drop-shadow(0 0 50px rgba(0,0,0,.08))',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
};

export default React.memo(Slide);
