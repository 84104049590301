import "./index.css";
import CardPost from "../../components/FeaturedNews/CardPost";
import { useContext } from "react";
import { PostContext } from "../../contexts/PostContext";

const News = () => {
  const { posts } = useContext(PostContext);

  return (
    <div className="newsroom">
      <div className="newsroom__container">
        <h1>Newsroom</h1>
        {posts?.map((post: any, index: number) => (
          <CardPost key={index} post={post} />
        ))}
      </div>
    </div>
  );
};
export default News;
