import "./index.css";
import CardPost from "./CardPost/index";
import { FiArrowRightCircle } from "react-icons/fi";
import { useContext } from "react";
import { PostContext } from "../../contexts/PostContext";

const FeaturedNews = () => {
  const { posts } = useContext(PostContext);
  
  return (
    <div className="featuredNews">
      <p style={{ fontSize: "20px" }}>
        <b>FEATURED NEWS</b>
      </p>
      <div className="featuredNews__container">
        {posts?.map((post: any, index: number) => (
          <CardPost key={index} post={post} />
        ))}
      </div>
      <a href="/news" className="featuredNews__link">
        <FiArrowRightCircle size="28px" color="#505E6B" />
        <p>See More News</p>
      </a>
    </div>
  );
};

export default FeaturedNews;
