import { Component } from 'react';
import './Index.css';
import inventory from '../../../assets/images/inventory.png';
import orders from '../../../assets/images/orders.png';
import prizes from '../../../assets/images/prizes.png';
import products from '../../../assets/images/products.png';
import venues from '../../../assets/images/venues.png';
import reports from '../../../assets/images/reports.png';
import discounts from '../../../assets/images/discounts.png';
import bars from '../../../assets/images/bars.png';
import Card from './Slide';

const images = [
  { img: inventory, title: 'Gestisci Inventario' },
  { img: prizes, title: 'Aggiungi Premi ai Dipendenti' },
  { img: reports, title: 'Analizza Rapporti' },
  { img: discounts, title: 'Aggiungi Sconti' },
  { img: orders, title: 'Gestisci Ordini' },
  { img: venues, title: 'Aggiungi, Modifica e Aggiorna Sedi' },
  { img: bars, title: 'Aggiungi Bar, Piani di Seduta e Gestisci Dipendenti' },
  { img: products, title: 'Aggiungi, Modifica e Aggiorna Prodotti' },
];

class Index extends Component {
  animation_in_progress: boolean;
  deck: any;
  state: any;
  constructor(props: any) {
    super(props);

    this.state = {
      cards: [],
    };

    this.animation_in_progress = false;
  }

  handle_next = () => {
    if (!this.animation_in_progress) {
      this.animation_in_progress = true;

      let last_cards_left =
        this.deck.children[this.deck.children.length - 1].style.left;
      let last_cards_zIndex =
        this.deck.children[this.deck.children.length - 1].style.zIndex;
      let last_cards_transform =
        this.deck.children[this.deck.children.length - 1].style.transform;
      let first_cards_opacity =
        this.deck.children[this.deck.children.length - 1].style.opacity;

      for (let i = this.deck.children.length - 1; i > 0; i--) {
        this.deck.children[i].style.transitionDuration = '0.4s';
        this.deck.children[i].style.left = this.deck.children[i - 1].style.left;
        this.deck.children[i].style.zIndex =
          this.deck.children[i - 1].style.zIndex;
        this.deck.children[i].style.transform =
          this.deck.children[i - 1].style.transform;
        this.deck.children[i].style.opacity =
          this.deck.children[i - 1].style.opacity;
      }

      this.deck.children[0].style.transitionDuration = '0.5s';
      this.deck.children[0].style.transform = `translate(-50%, -50%) scale(0)`;

      setTimeout(() => {
        this.deck.children[0].style.transitionDuration = '0.0s';
        this.deck.children[0].style.left = last_cards_left;
        this.deck.children[0].style.zIndex = last_cards_zIndex;
        this.deck.children[0].style.opacity = first_cards_opacity;
        this.deck.appendChild(this.deck.children[0]);

        setTimeout(() => {
          this.deck.children[
            this.deck.children.length - 1
          ].style.transitionDuration = '0.5s';
          this.deck.children[this.deck.children.length - 1].style.transform =
            last_cards_transform;

          this.animation_in_progress = false;
        }, 100);
      }, 100);
    } else {
      return;
    }
  };

  handle_previous = () => {
    if (!this.animation_in_progress) {
      this.animation_in_progress = true;

      let first_cards_left = this.deck.children[0].style.left;
      let first_cards_zIndex = this.deck.children[0].style.zIndex;
      let first_cards_transform = this.deck.children[0].style.transform;
      let first_cards_opacity = this.deck.children[0].style.opacity;

      for (let i = 0; i < this.deck.children.length - 1; i++) {
        this.deck.children[i].style.transitionDuration = '0.4s';

        this.deck.children[i].style.left = this.deck.children[i + 1].style.left;
        this.deck.children[i].style.zIndex =
          this.deck.children[i + 1].style.zIndex;
        this.deck.children[i].style.transform =
          this.deck.children[i + 1].style.transform;
        this.deck.children[i].style.opacity =
          this.deck.children[i + 1].style.opacity;
      }

      this.deck.children[
        this.deck.children.length - 1
      ].style.transitionDuration = '0.5s';
      this.deck.children[
        this.deck.children.length - 1
      ].style.transform = `translate(-50%, -50%) scale(0)`;

      setTimeout(() => {
        this.deck.children[
          this.deck.children.length - 1
        ].style.transitionDuration = '0.0s';
        this.deck.children[this.deck.children.length - 1].style.left =
          first_cards_left;
        this.deck.children[this.deck.children.length - 1].style.zIndex =
          first_cards_zIndex;
        this.deck.children[this.deck.children.length - 1].style.opacity =
          first_cards_opacity;
        this.deck.insertBefore(
          this.deck.children[this.deck.children.length - 1],
          this.deck.children[0]
        );

        setTimeout(() => {
          this.deck.children[0].style.transitionDuration = '0.5s';
          this.deck.children[0].style.transform = first_cards_transform;
          this.animation_in_progress = false;
        }, 100);
      }, 100);
    } else {
      return;
    }
  };

  componentDidMount() {
    let new_cards: any = [];
    let center = {
      x: parseFloat(this.deck.style.width) / 2,
      y: parseFloat(this.deck.style.height) / 2,
    };
    let total_number_of_cards = 3;
    let middle_card_by_index = Math.floor(total_number_of_cards / 2);
    let new_x = 0;
    let new_y = 0;
    let new_zIndex = 0;
    let new_scale = 1;
    let opacity;

    for (let i = 0; i < 8; i++) {
      if (i === middle_card_by_index - 1) {
        new_x = center.x - 300 * (middle_card_by_index - i);
        new_y = center.y;

        new_x = new_x + 0.333 * 300 * (middle_card_by_index - i);

        new_zIndex = i;
        opacity = '0.5';

        new_scale = Math.pow(0.75, middle_card_by_index - i);
      } else if (i === middle_card_by_index + 1) {
        new_x = center.x + 300 * (i - middle_card_by_index);
        new_y = center.y;
        opacity = '0.5';

        new_x = new_x - 0.333 * 300 * (i - middle_card_by_index);

        new_zIndex = i * -1.0;

        new_scale = Math.pow(0.75, i - middle_card_by_index);
      } else if (i === middle_card_by_index) {
        new_x = 200;
        new_scale = Math.pow(0.9, i - middle_card_by_index);
        opacity = '1.0';
      } else {
        new_x = 100;
        new_scale = Math.pow(0.7, i - middle_card_by_index);
      }

      new_cards.push(
        <Card
          x={new_x}
          y={new_y}
          opacity={opacity}
          z_index={i === middle_card_by_index ? 100 : new_zIndex}
          scale={new_scale}
          picsum_image={images[i]}
        />
      );
    }

    this.setState({ cards: new_cards });
  }

  render() {
    return (
      <div className='carousel'>
        <div
          className='carousel__prevArrow'
          onClick={this.handle_previous}
        ></div>
        <div style={styles.cardContainer}>
          <div ref={(ref_id) => (this.deck = ref_id)} style={styles.deck}>
            {this.state.cards}
          </div>
        </div>
        <div className='carousel__nextArrow' onClick={this.handle_next}></div>
      </div>
    );
  }
}

const styles = {
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '566px',
  },
  deck: {
    transform: 'translate(0%, 0%)',
    height: '300px',
    width: '400px',
  },
};

export default Index;
