import { createContext, PropsWithChildren, useEffect, useState } from "react";

type PostContextType = {
  faqs: any;
  posts: any;
  stage: "dev" | "prod";
  fetchAll: () => Promise<void>;
};

export const PostContext = createContext<PostContextType>(
  {} as PostContextType
);

export const PostContextProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [faqs, setFaqs] = useState([]);
  const [posts, setPosts] = useState([]);
  const [stage, setStage] = useState<"dev" | "prod">("dev");

  useEffect(() => {
    setStage(process.env.NODE_ENV === "development" ? "dev" : "prod");
  }, []);

  const fetchData = async () => {
    try {
      const stage = process.env.NODE_ENV === "development" ? "dev" : "prod";

      const postData = await (
        await fetch(
          `https://drinking-website.s3.eu-west-2.amazonaws.com/${stage}/json/posts.json`
        )
      ).json();
      setPosts(postData);

      const faqData = await (
        await fetch(
          `https://drinking-website.s3.eu-west-2.amazonaws.com/${stage}/json/faqs.json`
        )
      ).json();
      setFaqs(faqData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PostContext.Provider value={{ fetchAll: fetchData, posts, stage, faqs }}>
      {children}
    </PostContext.Provider>
  );
};
