import { useState } from 'react';
import './index.css';
import { BsCreditCard } from 'react-icons/bs';
import { IoLayersOutline } from 'react-icons/io5';
import { IoRocketOutline } from 'react-icons/io5';

import { IoSpeedometerOutline } from 'react-icons/io5';
import { AiOutlineLineChart } from 'react-icons/ai';
import { GiTeacher } from 'react-icons/gi';





const data = [
  {
    inActive: 'All in One Solution',
    icon: (
      <IoLayersOutline
        size='30px'
        color='#FDC00B'
        style={{ cursor: 'pointer', alignSelf: 'center', height: '25%' }}
      />
    ),
    inActiveList: [
      'Process orders, control queues & manage inventory - all in one place',
      'No third party companies',
    ],
    activeList: [
      'Alongside our app, we have a ‘back office’ that handles everything a till does, such as inventory management, stock checks, sales performance, cash up and more.',
      'Meaning you do not have to use 3rd party companies creating a seamless, integrated system with everything in one place.',
    ],
    active: 'All in One Solution',
  },
  {
    inActive: 'Transaction Fees',
        icon: (
      <BsCreditCard
        size='26px'
        color='#FDC00B'
        style={{ cursor: 'pointer', alignSelf: 'center', height: '25%' }}
      />
    ),
    inActiveList: [
      'No subscription costs',
      'Simple and low fee structure',
    ],
    activeList: [
      'We currently only take a 1% payment processing fee per transaction processed through the app.',
      'For example: the cost for a £20.00 order would be £0.20.',
    ],
    active: 'Transaction Fees',
  },
  {
    inActive: 'Increased Orders and Effeciency',
        icon: (
      <IoRocketOutline
        size='26px'
        color='#FDC00B'
        style={{ cursor: 'pointer', alignSelf: 'center', height: '25%' }}
      />
    ),
    inActiveList: [
      'Generate more sales by easing the payment process',
      'Embracing technology smoothes your service',
    ],
    activeList: [
      'Ordering apps are proven to save venues time, money and generate more sales. They free up bartenders, allowing them to focus on smoother operations behind the bar and for the customers.',
      'Reducing human error is also key to a well run venue and being able to plan ahead while preparing orders increases efficiency.',
    ],
    active: 'Increased Orders and Effeciency',
  },
  {
    inActive: 'Quick Setup',
        icon: (
      <IoSpeedometerOutline
        size='26px'
        color='#FDC00B'
        style={{ cursor: 'pointer', alignSelf: 'center', height: '25%' }}
      />
    ),
    inActiveList: ['Up & running within an hour', 'Zero setup costs'],
    activeList: [
      'We are very quick to setup, taking around an hour once on site to be up and running.',
      'All we have to do is upload your menu and stock reserves, create your employee profiles and you’re ready to go.',
    ],
    active: 'Quick Setup',
  },
  {
    inActive: 'Get to know your Customers better',
        icon: (
      <AiOutlineLineChart
        size='26px'
        color='#FDC00B'
        style={{ cursor: 'pointer', alignSelf: 'center', height: '25%' }}
      />
    ),
    inActiveList: [
      'Understand your customers preferences',
      'Plan ahead with stock purchases',
    ],
    activeList: [
      'Being able to view the sales performance of your venue is useful in understanding the nature of your customers, their preferences and how to better cater for them.',
      'You can track the performance of certain products; knowing this is vital in being able to improve future stock orders and planning of operations.',
    ],
    active: 'Get to know your Customers better',
  },
  {
    inActive: 'On Site Training',
        icon: (
      <GiTeacher
        size='26px'
        color='#FDC00B'
        style={{ cursor: 'pointer', alignSelf: 'center', height: '25%' }}
      />
    ),
    inActiveList: ['Product demonstation', '24/7 assistance'],
    activeList: [
      'We pride ourselves on our free on site training. This entails making sure that your employees are taken through how to maximise our services and how everything integrates.',
      'We are also available to contact 24/7 should you need our assistance in person or just online.',
    ],
    active: 'On Site Training',
  },
];

const Index = () => {
  const [isShown, setIsShown]: any = useState(null);
  return (
    <div className='whyChooseUs'>
      <h1>Why choose us?</h1>

      <div className='whyChooseUs__container'>
        {data.map((val: any, index) => (
          <button
            key={index}
            className='whyChooseUs__box'
            onMouseEnter={() => setIsShown(index)}
            onMouseLeave={() => setIsShown(null)}
          >
            {isShown === index ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  minHeight: '90%',
                  width: '92%',
                }}
              >
                <h3
                  style={{ marginTop: '5%', marginBottom: 0, fontSize: '18px' }}
                >
                  {val.active}
                </h3>
                <ul style={{ listStyle: 'none', height: 'auto', padding: 0 }}>
                  {val.activeList.map((item: any, index: any) => (
                    <li key={index} style={{ padding: '1%', fontSize: '15px' }}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  height: '100%',
                  width: '92%',
                }}
              >
                {val.icon}
                <h3 style={{ textAlign: 'center', fontSize: '18px' }}>
                  {val.inActive}.
                </h3>
                <ul style={{ textAlign: 'left' }}>
                  {val.inActiveList.map((item: any, index: any) => (
                    <li
                      key={index}
                      style={{ paddingBottom: '2%', fontSize: '15px' }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Index;
