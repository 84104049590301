import { useEffect, useState } from 'react';
import './Index.css';

const CookieConsent = () => {
  const [cookieConsent, setCookieConsent]: any = useState(null);

  const findCookie = (name: any) => {
    let c = document.cookie
      .split('; ')
      .find((cookie) => cookie && cookie.startsWith(name + '='));
    return c ? c.split('=')[1] : false;
  };

  useEffect(() => {
    if (findCookie('cookie-accepted') !== 'true') {
      setCookieConsent(false);
    }
  }, [cookieConsent]);

  const setCookie = () => {
    document.cookie = 'cookie-accepted=true';
    setCookieConsent(true);
  };

  // const resetCookie = () => {
  //     document.cookie = 'cookie-accepted=false';
  //     setCookieConsent(false);
  //   };

  return (
    <>
      {cookieConsent === false && (
        <div className='cookie'>
          <div className='cookie__grid'>
            <p>
              This website uses cookies to provide you with a great user
              experience and for functional purposes.
            </p>
            <button onClick={setCookie}>I Accept</button>
          </div>
        </div>
      )}
      {/* <button onClick={resetCookie}>Reset</button> */}
    </>
  );
};

export default CookieConsent;
