import './index.css';

const Index = () => {
  return (
    <div className="partnerships">
      <div className="partnerships__container">
        <h1>Our Partnerships</h1>
        <a href="https://www.sumup.com/en-gb/drinking/" target="_blank" rel="noopener noreferrer" className="partnerships__item">
          <div className="partnerships__content">
            <h2>SumUp</h2>
            <p>
              We have partnered with SumUp to provide seamless payment solutions.
              Our integration with SumUp's card readers allows direct interfacing with our bartender app.
            </p>
          </div>
          <img
            src="https://drinking-website.s3.eu-west-2.amazonaws.com/prod/assets/SumUp.jpg"
            alt="SumUp"
            className="partnerships__image"
          />
        </a>
        <a href="/Bruschi" className="partnerships__item">
          <div className="partnerships__content">
            <h2>Bruschi&C.</h2>
            <p>
              Bruschi&C. is a software redistributor in Italy that will be promoting us to their current client base.
            </p>
          </div>
          <img
            src="https://drinking-website.s3.eu-west-2.amazonaws.com/prod/assets/Bruschi.png"
            alt="Bruschi&C."
            className="partnerships__image"
          />
        </a>
      </div>
    </div>
  );
};

export default Index;
