import { useContext, useEffect } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import "./index.css";
import { PostContext } from "../../contexts/PostContext";

const Index = ({ post }: any) => {
  const { stage } = useContext(PostContext);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="post">
      <div className="post__details">
        <a href="/news" className="post__newsRoom">
          <FiArrowLeftCircle size="28px" color="#505E6B" />
          <p style={{ paddingLeft: "1%" }}>Back to Newsroom</p>
        </a>
        <h3 className="post__productName">{post?.productName}</h3>
        <h1 className="post__title">{post?.title}</h1>
        <p className="post__date">{post?.date}</p>
      </div>
      {post?.img1 && (
        <img
          className="post__img1"
          src={`https://drinking-website.s3.eu-west-2.amazonaws.com/${stage}/assets/${post?.img1}`}
        />
      )}
      <article className="post__paragraph">{post?.paragraph1}</article>
      <article className="post__paragraph">{post?.paragraph2}</article>
      {post?.img2 && (
        <div className="post__imgBk">
          <img
            className="post__img2"
            src={`https://drinking-website.s3.eu-west-2.amazonaws.com/${stage}/assets/${post?.img2}`}
          />
        </div>
      )}
      <article className="post__paragraph">{post?.paragraph3}</article>
    </div>
  );
};
export default Index;
