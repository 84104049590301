import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy/Index";
import TermsConditions from "./pages/TermsConditions/Index";
import Nav from "./components/Nav";
import Menu from "./components/Menu";
import CookieConsent from "./components/CookieConsent/Index";
import Footer from "./components/Footer/Index";
import Video from "./components/Video";
import Post from "./pages/Post";
import FAQ from "./pages/FAQ/index";
import News from "./pages/News";
import DeleteAccount from "./pages/DeleteAccount";
import { PostContext } from "./contexts/PostContext";
import Bruschi from './pages/Bruschi';
import Partnerships from './pages/Partnerships';
import Unsubscribe from './pages/Unsubscribe';
import '@brainhubeu/react-carousel/lib/style.css';

const App = () => {
  const [isSticky, setSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { posts, fetchAll } = useContext(PostContext);

  const toggleMenu = (e: any) => {
    setShowMenu(!showMenu);
    e.stopPropagation();
  };

  useEffect(() => {
    (async () => {
      await fetchAll();
    })();

    const handleScroll = () => {
      if (window.scrollY >= 20) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Router>
        <Nav isSticky={isSticky} toggleMenu={toggleMenu} />
        <Menu showMenu={showMenu} toggleMenu={toggleMenu} />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/how-does-drinking-work">
            <Video />
          </Route>
          <Route exact path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/terms">
            <TermsConditions />
          </Route>
          <Route
            exact
            path="/news/:title"
            render={({ location }: any) => {
              const splitString = location.pathname.split("/");
              const joinArr = splitString[2].split("-").join(" ");
              console.log("joinArr:", location);
              const post = posts?.find((post: any) => post.title === joinArr);
              console.log('post', post);
              
              return <Post key={`post_${location}`} post={post} />;
            }}
          />
          <Route exact path="/faq">
            <FAQ />
          </Route>
          <Route exact path="/news">
            <News />
          </Route>
          <Route exact path="/delete-account">
            <DeleteAccount />
          </Route>
          <Route exact path="/bruschi">
            <Bruschi/>
          </Route>
          <Route exact path="/partnerships">
            <Partnerships />
          </Route>
          <Route exact path="/unsubscribe/:encodedEmail">
            <Unsubscribe />
          </Route>
        </Switch>
        <CookieConsent />
        <Footer />
      </Router>
    </>
  );
};

export default App;
