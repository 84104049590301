import { useEffect, useRef, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

import './index.css';

type Props = {
  label: string;
  content: string;
  index: string;
};

const Index = ({ label, content, index, toggle, clicked }: any) => {

  const parentRef: any = useRef();


  return (
    <div className='collapsible'>
      <div className='collapsible__label' onClick={() => toggle(index)}>
        <h1>{label}</h1>
        { clicked === index ? <FaMinus size='18px' /> : <FaPlus size='18px' />}
      </div>
      <div
        className={'collapsible__content'}
        ref={parentRef}
        style={
            clicked === index
            ? { height: parentRef.current.scrollHeight + 'px' }
            : { height: '0px' }
        }
      >
       <div className="collapsible__contentContainer">{content}</div> 
      </div>
    </div>
  );
};
export default Index;
