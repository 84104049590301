import appStoreBadge from '../../assets/images/black.svg';
import googlePlayBadge from '../../assets/images/google-play-badge.png';
import logo from '../../assets/images/DrinKingLogo.png';
import './index.css';

const index = ({ showMenu, toggleMenu }: any) => {
  const signIn = () => {
    window.location.href = 'http://drinking.uk';
  };

  return (
    <>
      <div className={`Modal ${showMenu ? 'Show' : ''}`}>
        <div
          className='Modal__Login'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <button className='Modal__login-button' onClick={signIn}>
            Sign in
          </button>
          <h3>
            <a onClick={toggleMenu} href='/#form'>
              Add your venue
            </a>
          </h3>
          <h3>
            <a onClick={toggleMenu} href='/faq'>
              FAQ
            </a>
          </h3>
          <h3>
            <a onClick={toggleMenu} href='/news'>
              Newsroom
            </a>
          </h3>
          <h3>
            <a onClick={toggleMenu} href='/partnerships'>
              Partnerships
            </a>
          </h3>
        </div>
        <div className='Modal__apps'>
          <div className='Modal__apps-logo'>
            <img alt='' src={logo} style={{ height: 65 }} />
            <h4 style={{ width: '60%' }}>Theres more to love in the app.</h4>
          </div>

          <div
            className='Modal__apps-links'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <a
              href='https://apps.apple.com/gb/app/drinking-app/id1562580887'
              target='_blank'
              rel='noreferrer'
            >
              <img src={appStoreBadge} style={{ height: 40 }} alt='App store' />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.drinking.app'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={googlePlayBadge}
                style={{ height: 60, width: 145 }}
                alt='Google Play Store'
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className={`Overlay ${showMenu ? 'Show' : ''}`}
        onClick={toggleMenu}
      />
    </>
  );
};

export default index;
