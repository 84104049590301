import './index.css';
import bannerImg from '../../assets/images/banner-image.png';

const index = () => {
  return (
    <div className='banner'>
      <div className='banner__desc'>
        <h1>
          The cashless way to{' '}
          <span style={{ color: '#FDC00B' }}>manage orders.</span>{' '}
        </h1>
        <p>
        Sign up your venue to find out how the DrinKing system can save your venue time, money and generate more sales.
        </p>
        <button><a href="/#form">Sign up your venue</a></button>
        
      </div>
      <div className='banner__image'>
        <img src={bannerImg} alt={bannerImg} />
      </div>
    </div>
  );
};

export default index;
