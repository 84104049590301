import React from 'react';
import './Index.css'

const Index = () => {
  return (
    <div className='terms'>
      <h4>PLEASE READ THESE LICENSE TERMS CAREFULLY</h4>
      <p>
        BY CLICKING ON THE "ACCEPT" BUTTON BELOW YOU AGREE TO THESE TERMS WHICH
        WILL BIND YOU.{' '}
      </p>
      <h4>Who we are and what this agreement does</h4>
      <p>
        We DRINKING LTD of 25 Clove Hitch Quay, London, United Kingdom, SW11 3TN
        license you to use:
      </p>
      <p>
        DrinKing mobile application software <span>(App)</span>, and any updates or
        supplements to it.
      </p>
      <p>
        The service you connect to via the App and the content we provide to you
        through it <span>(Service)</span>.
      </p>
      <p>as permitted in these terms.</p>
      <h4>Your privacy</h4>
      <p>
        We only use any personal data we collect through your use of the App and
        the Services in the ways set out in our privacy policy [LINK TO PRIVACY
        POLICY] <span>OR</span> which can be found at www.drinking.co.uk/privacy.
      </p>
      <p>
        Please be aware that internet transmissions are never completely private
        or secure and that any message or information you send using the App or
        any Service may be read or intercepted by others, even if there is a
        special notice that a particular transmission is encrypted.
      </p>
      <h4>Apple store and google play store's terms also apply</h4>
      <p>
        The ways in which you can use the App and Documentation may also be
        controlled by the DrinKing's rules and policies and APPSTORE'S NAME]'s
        rules and policies will apply instead of these terms where there are
        differences between the two.
      </p>

      <h4>Operating system requirements</h4>
      <p>
        This app requres a [TYPE OF MOBILE TELEPHONE OR HANDHELD DEVICE] device
        with a minimum of [AMOUNT OF MEMORY] of memory and the [TYPE OF
        OPERATING SYSTEM] operating system [VERSION OF OPERATING SYSTEM] .
      </p>
      <h4>Support for the App and how to tell us about problems</h4>
      <p>
        <span>Support</span>. If you want to learn more about the App or the Service or
        have any problems using them please take a look at our support resources
        at www.drinking.co.uk/support.{' '}
      </p>

      <p>
        <span>Contacting us (including with complaints).</span> If you think the App or
        the Services are faulty or misdescribed or wish to contact us for any
        other reason please email our customer service team at
        admin@drinking.co.uk or call them on 07557029112.{' '}
      </p>
      <p>
        <span>How we will communicate with you</span>. If we have to contact you we will
        do so by email, by SMS or by pre-paid post, using the contact details
        you have provided to us.
      </p>
      <h4>How you may use the App</h4>
      <p>In return for your agreeing to comply with these terms you may:</p>
      <p>
        download or stream a copy of the App onto and view, use and display the
        App and the Service on such devices for your personal purposes only; and{' '}
      </p>
      <p>
        provided you comply with the License Restrictions set out in this
        agreement you may make a copy of the App for back-up purposes; and
      </p>
      <p>
        receive and use any free supplementary software code or update of the
        App incorporating "patches" and corrections of errors as we may provide
        to you.
      </p>
      <h4>You must be 18 to accept these terms and buy the app</h4>
      <p>You must be 18 or over to accept these terms and buy the App. </p>
      <h4>You may not transfer the App to someone else</h4>
      <p>
        We are giving you personally the right to use the App and the Service as
        set out above. You may not otherwise transfer the App or the Service to
        someone else, whether for money, for anything else or for free. If you
        sell any device on which the App is installed, you must remove the App
        from it.
      </p>
      <h4>Changes to these terms</h4>
      <p>
        We may need to change these terms to reflect changes in law or best
        practice or to deal with additional features which we introduce.
      </p>
      <p>
        We will give you at least [30] days notice of any change by sending you
        an SMS or email with details of the change or notifying you of a change
        when you next start the App.{' '}
      </p>
      <p>
        If you do not accept the notified changes you may continue to use the
        App and the Service in accordance with the existing terms but certain
        new features may not be available to you. We also reserve the right to
        notify you that failure to accept the notified changes will result in
        you not being permitted to continue to use the App and the Service.
      </p>
      <h4>Update to the App and changes to the Service</h4>
      <p>
        From time to time we may automatically update the App and change the
        Service to improve performance, enhance functionality, reflect changes
        to the operating system or address security issues. Alternatively we may
        ask you to update the App for these reasons.{' '}
      </p>
      <p>
        If you choose not to install such updates or if you opt out of automatic
        updates you may not be able to continue using the App and the Services.{' '}
      </p>
      <h4>If someone else owns the phone or device you are using</h4>
      <p>
        If you download or stream the App onto any phone or other device not
        owned by you, you must have the owner's permission to do so. You will be
        responsible for complying with these terms, whether or not you own the
        phone or other device.{' '}
      </p>
      <h4>We may collect technical data about your device</h4>
      <p>
        By using the App or any of the Services, you agree to us collecting and
        using technical information about the devices you use the App on and
        related software, hardware and peripherals to improve our products and
        to provide any Services to you.
      </p>
      <h4>
        We may collect location data (but you can turn location services off)
      </h4>
      <p>
        Certain Services [including Google Maps], will make use of location data
        sent from your devices. You can turn off this functionality at any time
        by turning off the location services settings for the App on the device.
        If you use these Services, you consent to us and our affiliates' and
        licensees' transmission, collection, retention, maintenance, processing
        and use of your location data and queries to provide and improve
        location-based and road traffic-based products and services.
      </p>
      <p>
        You may stop us collecting such data at any time by turning off the
        location services settings on your device.
      </p>
      <h4>We are not responsible for other websites you link to</h4>
      <p>
        The App or any Service may contain links to other independent websites
        which are not provided by us. Such independent sites are not under our
        control, and we are not responsible for and have not checked and
        approved their content or their privacy policies (if any).{' '}
      </p>
      <p>
        You will need to make your own independent judgement about whether to
        use any such independent sites, including whether to buy any products or
        services offered by them.
      </p>
      <h4>Licence restrictions</h4>
      <p>You agree that you will:</p>
      <p>
        not rent, lease, sub-license, loan, provide, or otherwise make
        available, the App or the Services in any form, in whole or in part to
        any person without prior written consent from us save as provided for in
        this agreement;
      </p>
      <p>
        not copy the App, Documentation or Services, except as part of the
        normal use of the App or where it is necessary for the purpose of
        back-up or operational security;
      </p>
      <p>
        not translate, merge, adapt, vary, alter or modify, the whole or any
        part of the App, Documentation or Services nor permit the App or the
        Services or any part of them to be combined with, or become incorporated
        in, any other programs, except as necessary to use the App and the
        Services on devices as permitted in these terms;
      </p>
      <p>
        not disassemble, de-compile, reverse engineer or create derivative works
        based on the whole or any part of the App or the Services nor attempt to
        do any such things, except to the extent that such actions cannot be
        prohibited because they are necessary to decompile the App to obtain the
        information necessary to create an independent program that can be
        operated with the App or with another program <span>(Permitted Objective)</span>,
        and provided that the information obtained by you during such
        activities:
      </p>
      <p>
        is not disclosed or communicated without the Licensor's prior written
        consent to any third party to whom it is not necessary to disclose or
        communicate it in order to achieve the Permitted Objective; and
      </p>
      <p>
        is not used to create any software that is substantially similar in its
        expression to the App;
      </p>
      <p>is kept secure; and</p>
      <p>is used only for the Permitted Objective;</p>
      <p>
        comply with all applicable technology control or export laws and
        regulations that apply to the technology used or supported by the App or
        any Service.
      </p>
      <h4>Acceptable use restrictions</h4>
      <p>You must: </p>
      <p>
        not use the App or any Service in any unlawful manner, for any unlawful
        purpose, or in any manner inconsistent with these terms, or act
        fraudulently or maliciously, for example, by hacking into or inserting
        malicious code, such as viruses, or harmful data, into the App, any
        Service or any operating system;
      </p>
      <p>
        not infringe our intellectual property rights or those of any third
        party in relation to your use of the App or any Service;
      </p>
      <p>
        not transmit any material that is defamatory, offensive or otherwise
        objectionable in relation to your use of the App or any Service;
      </p>
      <p>
        not use the App or any Service in a way that could damage, disable,
        overburden, impair or compromise our systems or security or interfere
        with other users; and
      </p>
      <p>
        not collect or harvest any information or data from any Service or our
        systems or attempt to decipher any transmissions to or from the servers
        running any Service.
      </p>
      <h4>Intellectual property rights</h4>
      <p>
        All intellectual property rights in the App, any documentation
        associated with the App, and the Services throughout the world belong to
        us and the rights in the App and the Services are licensed (not sold) to
        you. You have no intellectual property rights in, or to, the App, or the
        Services other than the right to use them in accordance with these
        terms.
      </p>
      <h4>Our responsibility for loss or damage suffered by you</h4>
      <p>
        <span>We are not liable for business losses.</span> The App is for domestic and
        private use. If you use the App for any commercial, business or resale
        purpose we will have no liability to you for any loss of profit, loss of
        business, business interruption, or loss of business opportunity.
      </p>
      <p>
        <span>Limitations to the App and the Services</span>. The App and the Services
        are provided for general information and entertainment purposes only.
        They do not offer advice on which you should rely. You must obtain
        professional or specialist advice before taking, or refraining from, any
        action on the basis of information obtained from the App or the Service.
        Although we make reasonable efforts to update the information provided
        by the App and the Service, we make no representations, warranties or
        guarantees, whether express or implied, that such information is
        accurate, complete or up to date.
      </p>
      <p>
        <span>Please back-up content and data used with the App</span>. We recommend that
        you back up any content and data used in connection with the App, to
        protect yourself in case of problems with the App or the Service.
      </p>
      <p>
        <span>Check that the App and the Services are suitable for you</span>. The App
        and the Services have not been developed to meet your individual
        requirements. Please check that the facilities and functions of the App
        and the Services as described on the site where you downloaded the App
        meet your requirements.
      </p>
      <p>
        <span>We are not responsible for events outside our control.</span> If our
        provision of the Services or support for the App or the Services is
        delayed by an event outside our control then we will contact you as soon
        as possible to let you know and we will take steps to minimise the
        effect of the delay.
      </p>
      <h4>
        We may end your rights to use the App and the Services if you break
        these terms
      </h4>
      <p>
        We may end your rights to use the App and Services at any time by
        contacting you if you have broken these terms in a serious way. If what
        you have done can be put right we will give you a reasonable opportunity
        to do so.
      </p>
      <p>If we end your rights to use the App and Services:</p>
      <p>
        You must stop all activities authorised by these terms, including your
        use of the App and any Services.
      </p>
      <p>
        You must delete or remove the App from all devices in your possession
        and immediately destroy all copies of the App which you have and confirm
        to us that you have done this.
      </p>
      <p>
        We may remotely access your devices and remove the App from them and
        cease providing you with access to the Services.
      </p>
      <h4>We may transfer this agreement to someone else</h4>
      <p>
        We may transfer our rights and obligations under these terms to another
        organisation. We will always tell you in writing if this happens and we
        will ensure that the transfer will not affect your rights under the
        contract.{' '}
      </p>
      <h4>You need our consent to transfer your rights to someone else</h4>
      <p>
        You may only transfer your rights or your obligations under these terms
        to another person if we agree in writing.
      </p>
      <h4>No rights for third parties</h4>
      <p>
        This agreement does not confer any rights on any third party nor shall
        any third party have any right to enforce any term of this agreement.
      </p>
      <h4>
        If a court finds part of this contract illegal, the rest will continue
        in force
      </h4>
      <p>
        Each of the paragraphs of these terms operates separately. If any court
        or relevant authority decides that any of them are unlawful, the
        remaining paragraphs will remain in full force and effect.
      </p>
      <h4>
        Even if we delay in enforcing this contract, we can still enforce it
        later
      </h4>
      <p>
        Even if we delay in enforcing this contract, we can still enforce it
        later. If we do not insist immediately that you do anything you are
        required to do under these terms, or if we delay in taking steps against
        you in respect of your breaking this contract, that will not mean that
        you do not have to do those things and it will not prevent us taking
        steps against you at a later date.
      </p>
      <h4>
        Which laws apply to this contract and where you may bring legal
        proceedings
      </h4>
      <p>
        These terms are governed by English law and you can bring legal
        proceedings in respect of the products in the English courts.
      </p>
      <h4>Alternative dispute resolution</h4>
      <p>
        Alternative dispute resolution is a process where an independent body
        considers the facts of a dispute and seeks to resolve it, without you
        having to go to court. If you are not happy with how we have handled any
        complaint, we may agree to enter into alternative dispute resolution
        with you to resolve any such complaint. PAGE 6
      </p>
    </div>
  );
};

export default Index;
