import './index.css';
import Customer from '../../components/Customer';
import Bartender from '../../components/Bartender';
import Form from '../../components/Form';
import Banner from '../../components/Banner';
import WhyChooseUs from '../../components/WhyChooseUs';
import BackOffice from '../../components/BackOffice/index';
import FeaturedNews from '../../components/FeaturedNews/index';


const Index = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Banner />
      <Customer />
      <Bartender />
      <BackOffice />
      <WhyChooseUs />
      <FeaturedNews />
      <Form />
    </div>
  );
};
export default Index;
