import React, { useState } from 'react';
import './Index.css';
import '@brainhubeu/react-carousel/lib/style.css';
import inventory from '../../../assets/images/inventory.png';
import orders from '../../../assets/images/orders.png';
import prizes from '../../../assets/images/prizes.png';
import products from '../../../assets/images/products.png';
import venues from '../../../assets/images/venues.png';
import reports from '../../../assets/images/reports.png';
import discounts from '../../../assets/images/discounts.png';
import bars from '../../../assets/images/bars.png';
import Carousel from '@brainhubeu/react-carousel';
import { GrNext } from 'react-icons/gr';
import { GrPrevious } from 'react-icons/gr';

let slides = [
  <div className='carousel__container'>
    <img src={inventory} alt='inventory' className='carousel__img' />
    <h4>Manage Inventory</h4>
  </div>,
  <div className='carousel__container'>
    <img src={orders} alt='orders' className='carousel__img' />
    <h4>Manage Orders</h4>
  </div>,
  <div className='carousel__container'>
    <img src={prizes} alt='prizes' className='carousel__img' />
    <h4>Add Employee Prizes</h4>
  </div>,
  <div className='carousel__container'>
    <img src={products} alt='products' className='carousel__img' />
    <h4>Add, Edit and Update Products</h4>
  </div>,
  <div className='carousel__container'>
    <img src={venues} alt='venues' className='carousel__img' />
    <h4>Add, Edit and Update Venues</h4>
  </div>,
  <div className='carousel__container'>
    <img src={reports} alt='reports' className='carousel__img' />
    <h4>Analyze Reports</h4>
  </div>,
  <div className='carousel__container'>
    <img src={discounts} alt='discounts' className='carousel__img' />
    <h4>Add Discounts</h4>
  </div>,
  <div className='carousel__container'>
    <img src={bars} alt='bars' className='carousel__img' />
    <h4>Add Bars, Seating plans and Manage Employees</h4>
  </div>,
];

const Index = () => {
  const [value, setValue] = useState(0);

  const onChange = (value: any) => {
    setValue(value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Carousel
        value={value}
        onChange={onChange}
        plugins={['centered', 'infinite']}
        slides={slides}
      />

      <div style={{ display: 'flex' }}>
        <button
          className='carousel__button'
          onClick={() => setValue(value - 1)}
        >
          <GrPrevious size='18px' />
        </button>

        <button
          className='carousel__button'
          onClick={() => setValue(value + 1)}
        >
          <GrNext size='18px' />
        </button>
      </div>
    </div>
  );
};

export default Index;
