import './index.css';
import { useMediaQuery } from 'react-responsive';
import ImageSliderMobile from './ImageSliderMobile/Index';
import ImageSlider from './ImageSlider/Index';

export default function Index() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  return (
    <div className='backOffice'>
      <div className='backOffice__title'>
        <h3>Ufficio Posteriore</h3>
      </div>
      <div className='carousel'>
        {isTabletOrMobile ? <ImageSliderMobile /> : <ImageSlider />}
      </div>
    </div>
  );
}
