import "./index.css";
import { useHistory } from "react-router-dom";
import { IoImagesOutline } from "react-icons/io5";
import { useContext } from "react";
import { PostContext } from "../../../contexts/PostContext";

const Index = ({ post }: any) => {
  const history = useHistory();
  const { stage } = useContext(PostContext);

  const goToArticle = (title: any, id: any) => {
    const removeSpaces = title.replace(/\s+/g, "-");
    history.push(`/news/${removeSpaces}`, { id });
  };

  return (
    <div className="cardPost">
      <div className="cardPost__imgWrapper">
        {post.img1 ? (
          <img
            onClick={() => goToArticle(post?.title, post?.id)}
            src={`https://drinking-website.s3.eu-west-2.amazonaws.com/${stage}/assets/${post.img1}`}
            className="cardPost__img"
          />
        ) : (
          <IoImagesOutline
            onClick={() => goToArticle(post?.title, post?.id)}
            className="cardPost__img"
            style={{ width: "70%" }}
          />
        )}
      </div>
      <h3 className="cardPost__productName">
        {post?.productName ? post.productName : "DrinKing"}
      </h3>
      <p
        className="cardPost__title"
        onClick={() => goToArticle(post?.title, post?.id)}
      >
        {post.title}
      </p>
      <p style={{ color: "#505E6B" }}>{post.date}</p>
    </div>
  );
};
export default Index;
