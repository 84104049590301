import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';

const Unsubscribe = () => {
  const { encodedEmail } = useParams<{ encodedEmail: string }>();
  const [message, setMessage] = useState('Unsubscribing...');

  useEffect(() => {
    const email = decodeURIComponent(atob(encodedEmail));

    const unsubscribe = async () => {
      try {
        const response = await fetch('https://167.99.192.169:5002/unsubscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          setMessage('You have successfully unsubscribed.');
        } else {
          setMessage('There was an error unsubscribing you. Please try again later.');
        }
      } catch (error) {
        setMessage('There was an error unsubscribing you. Please try again later.');
      }
    };

    unsubscribe();
  }, [encodedEmail]);

  return (
    <div className="unsubscribe">
      <h1>{message}</h1>
    </div>
  );
};

export default Unsubscribe;