import React from 'react';
import './Index.css';

const Index = () => {
  return (
    <div className='privacyPolicy'>
      <h1>Privacy Policy</h1>
      <p>
        Drinking LTD built the Drinking app as a Free app. This SERVICE is
        provided by Drinking LTD at no cost to the end user.
      </p>
      <p>
        This statement is used to inform visitors regarding our policies in
        relation to the collection, use, and disclosure of Personal Information
        relating to individuals who decide to use our Service.{' '}
      </p>
      <h4>Consent to installation of the App</h4>
      <p>
        Under data protection laws, we are required to provide you with certain
        information about who we are, how we process your personal data and for
        what purposes, and your rights in relation to your personal data. This
        information is provided in this document and it is important that you
        read this document carefully.
      </p>
      <p>
        Before installation of this App, please indicate your consent to our
        processing of your personal data (including your name, contact details,
        financial and device information) as described.
      </p>
      <p>
        <span>YES</span> I consent to the installation of the App for the
        purposes of carrying out the service provided by the App. NO I do not
        consent to the installation of the App.{' '}
      </p>
      <p>
        <span>NO</span> I do not consent to the installation of the App.
      </p>
      <h4>How you can withdraw consent</h4>
      <p>
        Once you provide consent by selecting "YES", you may change your mind
        and withdraw consent at any time by contacting us admin@drinking.co.uk
        but that will not affect the lawfulness of any processing carried out
        before you withdraw your consent.{' '}
      </p>
      <h4>Consent to processing Location Data</h4>
      <p>
        <span>YES</span> I consent to processing of my Location Data ([including
        details of my current location disclosed by [GPS technology to allow my
        contract with DrinKing to be performed.{' '}
      </p>
      <p>
        <span>NO</span> I do not consent to processing of my Location Data and
        location-enabled Services are disabled in my settings.
      </p>
      <h4>Location Data</h4>
      <p>
        DrinKing <span>(we)</span> are committed to protecting your personal
        data and respecting your privacy.
      </p>
      <h4>Introduction</h4>
      <p>
        This policy (together with our end-user licence agreement as set out at
        [WEB ADDRESS OF EULA] (EULA) and any additional terms of use
        incorporated by reference into the EULA, together our Terms of Use)
        applies to your use of:
      </p>
      <ul>
        <li>
          DrinKing mobile application software <span>(App)</span> hosted on
          <a href='https://apps.apple.com/gb/app/drinkingapp/id1562580887'>
            {' '}
            {`https://apps.apple.com/gb/app/
              drinkingapp/id1562580887`}
          </a>{' '}
          and
          <a href='https://play.google.com/store/apps/details?id=com.drinking.app'>
            {' '}
            {`https://play.google.com/store
            /apps/details?id=com.drinking.app`}
          </a>{' '}
          <span>(App Site)</span>, once you have downloaded or streamed a copy
          of the <span>App</span> onto your mobile telephone or handheld device
          <span>(Device)</span>.
        </li>
        <li>
          Any of the services accessible through the App <span>(Services)</span>{' '}
          that are available on the App Site or other sites of ours{' '}
          <span>(Services Sites)</span>. This policy sets out the basis on which
          any personal data we collect from you, or that you provide to us, will
          be processed by us. This App is not intended for children and we do
          not knowingly collect data relating to children. Please read the
          following carefully to understand our practices regarding your
          personal data and how we will treat it.
        </li>
      </ul>
      <p>
        This policy is provided in a layered format so you can click through to
        the specific areas set out below. [Alternatively, you can download a pdf
        version of the policy here:]{' '}
      </p>
      <p>[IMPORTANT INFORMATION AND WHO WE ARE]</p>
      <p>[THE DATA WE COLLECT ABOUT YOU]</p>
      <p>[HOW IS YOUR PERSONAL DATA COLLECTED?]</p>
      <p>[HOW WE USE YOUR PERSONAL DATA]</p>
      <p>[DISCLOSURES OF YOUR PERSONAL DATA]</p>
      <p>[INTERNATIONAL TRANSFERS]</p>
      <p>[DATA SECURITY]</p>
      <p>[DATA RETENTION]</p>
      <p>[YOUR LEGAL RIGHTS]</p>
      <p>[GLOSSARY]</p>
      <p>[DESCRIPTION OF CATEGORIES OF PERSONAL DATA]</p>
      <h4>Important information and who we are</h4>
      <p>
        DRINKING LTD is the controller and is responsible for your personal data
        (collectively referred to as ["DrinKing", "we", "us" or "our" in this
        policy).
      </p>
      <h4>Contact details</h4>
      <p>Our full details are:</p>
      <ul>
        <li>Full name of legal entity: DRINKING LTD</li>
        <li>Email address: admin@drinking.co.uk</li>
        <li>
          Postal address: 25 Clove Hitch Quay, London, United Kingdom, SW11 3TN
        </li>
        <li>Telephone number: 07557029112</li>
      </ul>
      <p>
        Depending on where you live you may have the right to make a complaint
        at any time to the regulator in your jurisdiction for data protection
        issues.{' '}
      </p>
      <h4>
        Changes to the privacy policy and your duty to inform us of changes
      </h4>
      <p>We keep our privacy policy under regular review. </p>
      <p>
        This version was last updated on 23/04/2021. It may change and if it
        does, these changes will be posted on this page and, where appropriate,
        notified to you by email or when you next start the App or log onto one
        of the Services Sites. The new policy may be displayed on-screen and you
        may be required to read and accept the changes to continue your use of
        the App or the Services.
      </p>
      <p>
        It is important that the personal data we hold about you is accurate and
        current. Please keep us informed if your personal data changes during
        our relationship with you.{' '}
      </p>
      <h4>Third party links</h4>
      <p>
        Our Sites may, from time to time, contain links to and from the websites
        of our partner networks, advertisers and affiliates. Please note that
        these websites and any services that may be accessible through them have
        their own privacy policies and that we do not accept any responsibility
        or liability for these policies or for any personal data that may be
        collected through these websites or services, such as Contact and
        Location Data. Please check these policies before you submit any
        personal data to these websites or use these services.
      </p>
      <h4>The data we collect about you</h4>
      <p>
        We may collect, use, store and transfer different kinds of personal data
        about you as follows:
      </p>
      <ul>
        <li>Identity Data.</li>
        <li>Contact Data.</li>
        <li>Financial Data.</li>
        <li>Transaction Data.</li>
        <li>Device Data</li>
        <li>Content Data.</li>
        <li>Profile Data</li>
        <li>Usage Data</li>
        <li>Marketing and Communications Data.</li>
        <li>Location Data</li>
      </ul>
      <p>We explain these categories of data herein.</p>
      <p>
        We also collect, use and share Aggregated Data such as statistical or
        demographic data for any purpose. Aggregated Data could be derived from
        your personal data but is not considered personal data in law as this
        data will not directly or indirectly reveal your identity. For example,
        we may aggregate your Usage Data to calculate the percentage of users
        accessing a specific App feature. However, if we combine or connect
        Aggregated Data with your personal data so that it can directly or
        indirectly identify you, we treat the combined data as personal data
        which will be used in accordance with this privacy policy.
      </p>
      <p>
        We do not collect any Special Categories of Personal Data about you
        (this includes details about your race or ethnicity, religious or
        philosophical beliefs, sex life, sexual orientation, political opinions,
        trade union membership, information about your health, and genetic and
        biometric data). Nor do we collect any information about criminal
        convictions and offences.
      </p>
      <h4>How is your personal data collected?</h4>
      <p>We will collect and process the following data about you:</p>
      <ul>
        <li>
          <span>Information you give us.</span> This is information (including
          Identity, Contact, Financial, and Marketing and Communications Data)
          you consent to giving us about you by filling in forms on the App Site
          and the Services Sites (together Our Sites), or by corresponding with
          us (for example, by email or chat). It includes information you
          provide when you register to use the App Site, download or register an
          App, subscribe to any of our Services, search for an App or Service,
          make an in-App purchase, share data via an App's social media
          functions, enter a competition, promotion or survey and when you
          report a problem with an App, our Services, or any of Our Sites. If
          you contact us, we will keep a record of that correspondence.{' '}
        </li>
        <li>
          <span>Information we collect about you and your device.</span> Each
          time you visit one of Our Sites or use one of our Apps we will
          automatically collect personal data including Device, Content and
          Usage Data. We collect this data using cookies and other similar
          technologies. Please see our cookie policy below for further details.
        </li>
        <li>
          <span>Location Data.</span> We also use GPS technology to determine
          your current location. Some of our location-enabled Services require
          your personal data for the feature to work. If you wish to use the
          particular feature, you will be asked to consent to your data being
          used for this purpose. You can withdraw your consent at any time by
          disabling Location Data in your settings.
        </li>
        <li>
          <span>
            Information we receive from other sources including third parties
            and publicly available sources.
          </span>{' '}
          We will receive personal data about you from various third parties as
          set out below:
        </li>
        <li>
          Device Data from the following parties: analytics providers [such as
          Google based outside the UK];
        </li>
        <li>
          Contact, Financial and Transaction Data from providers of technical,
          payment and delivery services [such as [Square] based [inside OR
          outside] the UK];
        </li>
        <li>
          <span>Unique application numbers.</span> When you want to install or
          uninstall a Service containing a unique application number or when
          such a Service searches for automatic updates, that number and
          information about your installation, for example, the type of
          operating system, may be sent to us.]
        </li>
      </ul>
      <h4>Cookies</h4>
      <p>
        We use cookies and/or other tracking technologies to distinguish you
        from other users of the App, App Site, the distribution platform
        (Appstore) or Services Sites and to remember your preferences. This
        helps us to provide you with a good experience when you use the App or
        browse any of Our Sites and also allows us to improve the App and Our
        Sites. For detailed information on the cookies we use, the purposes for
        which we use them and how you can exercise your choices regarding our
        use of your cookies.
      </p>
      <h4>How we use your personal data</h4>
      <p>
        We will only use your personal data when the law allows us to do so.
        Most commonly we will use your personal data in the following
        circumstances:{' '}
      </p>
      <ul>
        <li>Where you have consented before the processing.</li>
        <li>
          Where we need to perform a contract we are about to enter or have
          entered with you.
        </li>
        <li>
          Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.
        </li>
        <li>Where we need to comply with a legal or regulatory obligation. </li>
      </ul>
      <p>
        We will only send you direct marketing communications by email or text
        if we have your consent. You have the right to withdraw that consent at
        any time by contacting us.{' '}
      </p>
      <p>
        We will get your express opt-in consent before we share your personal
        data with any third party for marketing purposes
      </p>
      <h4>Disclosures of your personal data</h4>
      <p>
        When you consent to providing us with your personal data, we will also
        ask you for your consent to share your personal data with the third
        parties set out below for the purposes set out in the table [Purposes
        for which we will use your personal data]:
      </p>
      <ul>
        <li>Internal Third Parties.</li>
        <li>External Third Parties.</li>
        <li>
          Specific third parties such as the establishment that provides you
          with food and drink ordered via the App.
        </li>
        <li>
          Third parties to whom we may choose to sell, transfer or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this privacy policy.
        </li>
      </ul>
      <h4>International transfers</h4>
      <p>
        Some of our external third parties, such as those who host our app cloud
        services are based outside the UK so their processing of your personal
        data will involve a transfer of data outside the UK.
      </p>
      <p>
        Whenever we transfer your personal data out of the UK, we ensure a
        similar degree of protection is afforded to it by ensuring at least one
        of the following safeguards is implemented:
      </p>
      <ul>
        <li>
          We will only transfer your personal data to countries that have been
          deemed to provide an adequate level of protection for personal data
        </li>
      </ul>
      <p>
        Please contact us if you want further information on the specific
        mechanism used by us when transferring your personal data out of the UK.
      </p>
      <h4>Data security</h4>
      <p>
        All information you provide to us is stored on our secure servers. Any
        payment transactions [carried out by us or our chosen third-party
        provider of payment processing services] will be encrypted using Secured
        Sockets Layer technology. Where we have given you (or where you have
        chosen) a password that enables you to access certain parts of Our
        Sites, you are responsible for keeping this password confidential. We
        ask you not to share a password with anyone.
      </p>
      <p>
        Once we have received your information, we will use strict procedures
        and security features to try to prevent your personal data from being
        accidentally lost, used or accessed in an unauthorised way.
      </p>
      <p>
        We will collect and store personal data on your Device using application
        data caches and browser web storage (including HTML5) and other
        technology.
      </p>
      <p>
        We have put in place procedures to deal with any suspected personal data
        breach and will notify you and any applicable regulator when we are
        legally required to do so.
      </p>
      <h4>Data retention</h4>
      <p>
        By law we have to keep basic information about our customers (including
        Contact, Identity, Financial and Transaction Data) for specified periods
        after they cease being customers.
      </p>
      <p>In some circumstances you can ask us to delete your data. </p>
      <p>
        In some circumstances we will anonymise your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes, in which case we may use this information indefinitely without
        further notice to you.
      </p>
      <p>
        In the event that you do not use the App for a period of five years then
        we will treat the account as expired and your personal data may be
        deleted.
      </p>
      <h4>Your legal rights</h4>
      <p>
        Under certain circumstances you have the following rights under data
        protection laws in relation to your personal data:
      </p>
      <ul>
        <li>Request access to your personal data</li>
        <li>Request correction of your personal data</li>
        <li>Request erasure of your personal data.</li>
        <li>Object to processing of your personal data.</li>
        <li>Request restriction of processing your personal data.</li>
        <li>Request transfer or your personal data. </li>
        <li>Right to withdraw consent. </li>
      </ul>
      <p>
        You also have the right to ask us not to continue to process your
        personal data for marketing purposes.{' '}
      </p>
      <p>
        You can exercise any of these rights at any time by contacting us at
        admin@drinking.co.uk.
      </p>
      <h4>Glossary</h4>
      <h4>Lawful basis</h4>
      <p>
        <span>Consent</span> means processing your personal data where you have
        signified your agreement by a statement or clear opt-in to processing
        for a specific purpose. Consent will only be valid if it is a freely
        given, specific, informed and unambiguous indication of what you want.
        You can withdraw your consent at any time by contacting us.{' '}
      </p>
      <p>
        <span>Legitimate Interest</span> means the interest of our business in
        conducting and managing our business to enable us to give you the best
        service/product and the best and most secure experience. We make sure we
        consider and balance any potential impact on you (both positive and
        negative) and your rights before we process your personal data for our
        legitimate interests. We do not use your personal data for activities
        where our interests are overridden by the impact on you (unless we have
        your consent or are otherwise required or permitted to by law). You can
        obtain further information about how we assess our legitimate interests
        against any potential impact on you in respect of specific activities by
        contacting us.
      </p>
      <p>
        <span>Performance of Contract</span> means processing your data where it
        is necessary for the performance of a contract to which you are a party
        or to take steps at your request before entering into such a contract.
      </p>
      <p>
        <span>Comply with a legal obligation</span> means processing your
        personal data where it is necessary for compliance with a legal
        obligation that we are subject to.
      </p>
      <p>External third parties</p>
      <p>
        Service providers acting as processors based in the Netherlands who
        provide IT and system administration services.
      </p>
      <p>
        Professional advisers acting as processors or joint controllers
        including lawyers, bankers, auditors and insurers based in the UK and
        Republic of Ireland who provide consultancy, banking, legal, insurance
        and accounting services.
      </p>
      <p>
        Revenue and Customs, regulators and other authorities acting as
        processors or joint controllers based in the UK who require reporting of
        processing activities in certain circumstances.
      </p>
      <p>Your legal rights</p>
      <p>You have the right to:</p>
      <ul>
        <li>
          <span>Request access</span> to your personal data (commonly known as a
          "data subject access request"). This enables you to receive a copy of
          the personal data we hold about you and to check that we are lawfully
          processing it.
        </li>
        <li>
          <span>Request correction</span>
          of the personal data that we hold about you. This enables you to have
          any incomplete or inaccurate data we hold about you corrected, though
          we may need to verify the accuracy of the new data you provide to us.
        </li>
        <li>
          <span>Request erasure </span>
          of your personal data. This enables you to ask us to delete or remove
          personal data where there is no good reason for us continuing to
          process it. You also have the right to ask us to delete or remove your
          personal data where you have successfully exercised your right to
          object to processing (see below), where we may have processed your
          information unlawfully or where we are required to erase your personal
          data to comply with local law. Note, however, that we may not always
          be able to comply with your request of erasure for specific legal
          reasons which will be notified to you, if applicable, at the time of
          your request.
        </li>
        <li>
          <span>Object to processing</span>
          of your personal data where we are relying on a legitimate interest
          (or those of a third party) and there is something about your
          particular situation which makes you want to object to processing on
          this ground as you feel it impacts on your fundamental rights and
          freedoms. You also have the right to object where we are processing
          your personal data for direct marketing purposes. In some cases, we
          may demonstrate that we have compelling legitimate grounds to process
          your information which override your rights and freedoms.
        </li>
        <li>
          <span> Request restriction of processing</span>
          of your personal data. This enables you to ask us to suspend the
          processing of your personal data in the following scenarios:
        </li>
        <ol type='a'>
          <li>if you want us to establish the data's accuracy;</li>
          <li>
            where our use of the data is unlawful but you do not want us to
            erase it;
          </li>
          <li>
            where you need us to hold the data even if we no longer require it
            as you need it to establish, exercise or defend legal claims; or
          </li>
          <li> you have objected to our use of y</li>
        </ol>
        <li>
          <span>Request the transfer</span>
          of your personal data to you or to a third party. We will provide to
          you, or a third party you have chosen, your personal data in a
          structured, commonly used, machine- 9 readable format. Note that this
          right only applies to automated information which you initially
          provided consent for us to use or where we used the information to
          perform a contract with you.
        </li>
        <li>
          <span> Withdraw consent at any time</span>
          where we are relying on consent to process your personal data.
          However, this will not affect the lawfulness of any processing carried
          out before you withdraw your consent. If you withdraw your consent, we
          may not be able to provide certain products or services to you. We
          will advise you if this is the case at the time you withdraw your
          consent.
        </li>
      </ul>
      <h4>Description of categories of personal data</h4>
      <ul>
        <li>
          <span>Identity Data:</span>
          [first name, last name, maiden name, username or similar identifier,
          marital status, title, date of birth, gender].
        </li>
        <li>
          <span>Contact Data: </span>
          [billing address, delivery address, email address and telephone
          numbers].
        </li>
        <li>
          <span>Financial Data:</span>
          [bank account and payment card details].
        </li>
        <li>
          <span>Transaction Data:</span>
          includes [details about payments to and from you and details of in-App
          purchases].{' '}
        </li>
        <li>
          <span>Device Data:</span>
          includes [the type of mobile device you use,] [a unique device
          identifier [(for example, your Device's IMEI number, the MAC address
          of the Device's wireless network interface, or the mobile phone number
          used by the Device)],] [mobile network information,] [your mobile
          operating system,] [the type of mobile browser you use,] [time zone
          setting,] .
        </li>
        <li>
          <span> Content Data:</span>
          includes information stored on your Device, including [friends'
          lists,] [login information,] [photos, videos or other digital
          content,] [check-ins,] [OTHER INFORMATION].
        </li>
        <li>
          <span>Profile Data:</span>
          includes [your username and password, in-App purchase history, your
          interests, preferences, feedback and survey responses].
        </li>
        <li>
          <span>Usage Data:</span>
          includes details of your use of any of our Apps or your visits to any
          of Our Sites including, but not limited to, [traffic data [and other
          communication data],] whether this is required for our own billing
          purposes or otherwise [and the resources that you access].
        </li>
        <li>
          <span>Marketing and Communications Data:</span>
          includes your preferences in receiving marketing from us and our third
          parties and your communication preferences.
        </li>
        <li>
          <span> Location Data:</span>
          includes your current location disclosed by .GPS technology
        </li>
      </ul>
    </div>
  );
};

export default Index;
