import './index.css';
import { FiMenu } from 'react-icons/fi';
import logo from '../../assets/images/logo@3x.png';
import { Link } from 'react-router-dom';

type NavProps = {
  isSticky: boolean;
  toggleMenu: (e: any) => void;
};

const Index = ({ isSticky, toggleMenu }: NavProps) => {
  const signIn = () => {
    window.location.href = 'http://drinking.uk';
  };
  return (
    <div className={isSticky ? 'navbar active' : 'navbar'}>
      <div className='navbar__menu'>
        <FiMenu
          size='26px'
          onMouseDown={toggleMenu}
          style={{ cursor: 'pointer' }}
        />
        <Link to='/'>
          <img
            src={logo}
            alt='DrinKing Logo'
            style={{
              height: '42.88px',
              paddingBottom: '2%',
              cursor: 'pointer',
            }}
          />
        </Link>
      </div>
      <div className='navbar__links'>
        <a href='/#form'>Add your Venue</a>
        <a href='/faq'>FAQ</a>
        <button
          className='navbar__login'
          style={{ cursor: 'pointer' }}
          onClick={signIn}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Index;
