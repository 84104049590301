import React from 'react';
import './Index.css';
import logo from '../../assets/images/logo@3x.png';
import { SiLinkedin } from 'react-icons/si';
import { FaInstagramSquare } from 'react-icons/fa';

const Index = () => {
  return (
    <div className='footer'>
      <img src={logo} alt={logo} />
      <div className='footer__links'>
        <a href='/terms'>Terms and Conditions</a>
        <a href='/privacy'>Privacy Policy</a>
        <a href='/delete-account'>Delete Your Account</a>
      </div>

      <div className='footer__socialLinks'>
        <a href='https://www.linkedin.com/company/drinking/about/'>
          <SiLinkedin size='25px' />
        </a>
        <a href='https://instagram.com/drinking.app?utm_medium=copy_link'>
          <FaInstagramSquare size='29px' />
        </a>
      </div>
    </div>
  );
};

export default Index;
